export const getActionSuffix = action => action.substring(action.indexOf('@') + 1, action.length);
/* istanbul ignore next */
export const getAction = action => action.substring(0, action.indexOf('@') + 1);

// HOURS
export const LIB_HOURS_LOADING = 'LIB_HOURS_LOADING';
export const LIB_HOURS_FAILED = 'LIB_HOURS_FAILED';
export const LIB_HOURS_LOADED = 'LIB_HOURS_LOADED';

// PRINT BALANCE
export const PRINT_BALANCE_LOADING = 'PRINT_BALANCE_LOADING';
export const PRINT_BALANCE_FAILED = 'PRINT_BALANCE_FAILED';
export const PRINT_BALANCE_LOADED = 'PRINT_BALANCE_LOADED';

// COMPUTER AVAIL
export const COMP_AVAIL_LOADING = 'COMP_AVAIL_LOADING';
export const COMP_AVAIL_FAILED = 'COMP_AVAIL_FAILED';
export const COMP_AVAIL_LOADED = 'COMP_AVAIL_LOADED';

// TRAINING EVENTS
export const TRAINING_LOADING = 'TRAINING_LOADING';
export const TRAINING_FAILED = 'TRAINING_FAILED';
export const TRAINING_LOADED = 'TRAINING_LOADED';

export const LEARNING_RESOURCE_SUGGESTIONS_LOADING = 'LEARNING_RESOURCE_SUGGESTIONS_LOADING';
export const LEARNING_RESOURCE_SUGGESTIONS_FAILED = 'LEARNING_RESOURCE_SUGGESTIONS_FAILED';
export const LEARNING_RESOURCE_SUGGESTIONS_LOADED = 'LEARNING_RESOURCE_SUGGESTIONS_LOADED';
export const LEARNING_RESOURCE_SUGGESTIONS_CLEAR = 'LEARNING_RESOURCE_SUGGESTIONS_CLEAR';

// Claim publication actions
export const POSSIBLY_YOUR_PUBLICATIONS_LOADING = 'POSSIBLY_YOUR_PUBLICATIONS_LOADING';
export const POSSIBLY_YOUR_PUBLICATIONS_LOADED = 'POSSIBLY_YOUR_PUBLICATIONS_LOADED';
export const POSSIBLY_YOUR_PUBLICATIONS_FAILED = 'POSSIBLY_YOUR_PUBLICATIONS_FAILED';

// Incomplete NTRO publication actions
export const INCOMPLETE_NTRO_PUBLICATIONS_LOADING = 'INCOMPLETE_NTRO_PUBLICATIONS_LOADING';
export const INCOMPLETE_NTRO_PUBLICATIONS_LOADED = 'INCOMPLETE_NTRO_PUBLICATIONS_LOADED';
export const INCOMPLETE_NTRO_PUBLICATIONS_FAILED = 'INCOMPLETE_NTRO_PUBLICATIONS_FAILED';

// Accounts/authors
export const CURRENT_ACCOUNT_LOADING = 'CURRENT_ACCOUNT_LOADING';
export const CURRENT_ACCOUNT_LOADED = 'CURRENT_ACCOUNT_LOADED';
export const CURRENT_ACCOUNT_ANONYMOUS = 'CURRENT_ACCOUNT_ANONYMOUS';
export const CURRENT_ACCOUNT_SESSION_EXPIRED = 'CURRENT_ACCOUNT_SESSION_EXPIRED';
export const CURRENT_ACCOUNT_SESSION_VALID = 'CURRENT_ACCOUNT_SESSION_VALID';
export const CLEAR_CURRENT_ACCOUNT_SESSION_FLAG = 'CLEAR_CURRENT_ACCOUNT_SESSION_FLAG';

export const CURRENT_AUTHOR_LOADING = 'CURRENT_AUTHOR_LOADING';
export const CURRENT_AUTHOR_LOADED = 'CURRENT_AUTHOR_LOADED';
export const CURRENT_AUTHOR_FAILED = 'CURRENT_AUTHOR_FAILED';
export const CURRENT_AUTHOR_SAVING = 'CURRENT_AUTHOR_SAVING';
export const CURRENT_AUTHOR_SAVED = 'CURRENT_AUTHOR_SAVED';
export const CURRENT_AUTHOR_SAVE_FAILED = 'CURRENT_AUTHOR_SAVE_FAILED';
export const CURRENT_AUTHOR_SAVE_RESET = 'CURRENT_AUTHOR_SAVE_RESET';

// Dashboard lure
export const APP_DASHBOARD_POSSIBLY_YOUR_PUBLICATIONS_LURE_HIDE = 'APP_DASHBOARD_POSSIBLY_YOUR_PUBLICATIONS_LURE_HIDE';
export const APP_ALERT_SHOW = 'APP_ALERT_SHOW';
export const APP_ALERT_HIDE = 'APP_ALERT_HIDE';
export const SET_REDIRECT_PATH = 'SET_REDIRECT_PATH';
export const CLEAR_REDIRECT_PATH = 'CLEAR_REDIRECT_PATH';

// GUIDES
export const GUIDES_LOADING = 'GUIDES_LOADING';
export const GUIDES_FAILED = 'GUIDES_FAILED';
export const GUIDES_LOADED = 'GUIDES_LOADED';
export const GUIDES_CLEAR = 'GUIDES_CLEAR';

// Exams for Learning Resources Panel & Page
export const EXAMS_LEARNING_RESOURCES_LOADING = 'EXAMS_LEARNING_RESOURCES_LOADING';
export const EXAMS_LEARNING_RESOURCES_FAILED = 'EXAMS_LEARNING_RESOURCES_FAILED';
export const EXAMS_LEARNING_RESOURCES_LOADED = 'EXAMS_LEARNING_RESOURCES_LOADED';
export const EXAMS_LEARNING_RESOURCES_CLEAR = 'EXAMS_LEARNING_RESOURCES_CLEAR';

// READING LIST
export const READING_LIST_LOADING = 'READING_LIST_LOADING';
export const READING_LIST_FAILED = 'READING_LIST_FAILED';
export const READING_LIST_LOADED = 'READING_LIST_LOADED';
export const READING_LIST_CLEAR = 'READING_LIST_CLEAR';

// ALERTS
export const ALERTS_LOADING = 'ALERTS_LOADING';
export const ALERTS_FAILED = 'ALERTS_FAILED';
export const ALERTS_LOADED = 'ALERTS_LOADED';
export const ALERTS_CLEAR = 'ALERTS_CLEAR';

export const ALERT_FAILED = 'ALERT_FAILED';
export const ALERT_LOADING = 'ALERT_LOADING';
export const ALERT_LOADED = 'ALERT_LOADED';
export const ALERT_SAVED = 'ALERT_SAVED';
export const ALERT_DELETED = 'ALERT_DELETED';
export const ALERT_CLEAR = 'ALERT_CLEAR';

// UPLOADING FILES TO S3 PUBLIC FILES
export const PUBLIC_FILE_UPLOADING = 'PUBLIC_FILE_UPLOADING';
export const PUBLIC_FILE_UPLOADED = 'PUBLIC_FILE_UPLOADED';
export const PUBLIC_FILE_UPLOAD_FAILED = 'PUBLIC_FILE_UPLOAD_FAILED';
export const PUBLIC_FILE_UPLOAD_CLEARED = 'PUBLIC_FILE_UPLOAD_CLEARED';

// Exam Suggestions
export const EXAM_SUGGESTIONS_LOADING = 'EXAM_SUGGESTIONS_LOADING';
export const EXAM_SUGGESTIONS_FAILED = 'EXAM_SUGGESTIONS_FAILED';
export const EXAM_SUGGESTIONS_LOADED = 'EXAM_SUGGESTIONS_LOADED';
export const EXAM_SUGGESTIONS_CLEAR = 'EXAM_SUGGESTIONS_CLEAR';

// Exam Results
export const EXAM_SEARCH_LOADING = 'EXAM_SEARCH_LOADING';
export const EXAM_SEARCH_FAILED = 'EXAM_SEARCH_FAILED';
export const EXAM_SEARCH_LOADED = 'EXAM_SEARCH_LOADED';
export const EXAM_SEARCH_CLEAR = 'EXAM_SEARCH_CLEAR';

// TEST AND TAG

// user
export const TESTTAG_USER_LOADING = 'TESTTAG_USER_LOADING';
export const TESTTAG_USER_LOADED = 'TESTTAG_USER_LOADED';
export const TESTTAG_USER_FAILED = 'TESTTAG_USER_FAILED';
export const TESTTAG_USER_CLEAR_ERROR = 'TESTTAG_USER_CLEAR_ERROR';

// Dashboard onload
export const TESTTAG_DASHBOARD_CONFIG_LOADING = 'TESTTAG_DASHBOARD_CONFIG_LOADING';
export const TESTTAG_DASHBOARD_CONFIG_LOADED = 'TESTTAG_DASHBOARD_CONFIG_LOADED';
export const TESTTAG_DASHBOARD_CONFIG_FAILED = 'TESTTAG_DASHBOARD_CONFIG_FAILED';
export const TESTTAG_DASHBOARD_CONFIG_CLEAR_ERROR = 'TESTTAG_DASHBOARD_CONFIG_CLEAR_ERROR';

// INSPECTION Initial config lists (sites, asset types, testing devices)
export const TESTTAG_INSPECTION_CONFIG_LOADING = 'TESTTAG_INSPECTION_CONFIG_LOADING';
export const TESTTAG_INSPECTION_CONFIG_LOADED = 'TESTTAG_INSPECTION_CONFIG_LOADED';
export const TESTTAG_INSPECTION_CONFIG_FAILED = 'TESTTAG_INSPECTION_CONFIG_FAILED';
export const TESTTAG_INSPECTION_CONFIG_CLEAR = 'TESTTAG_INSPECTION_CONFIG_CLEAR';
export const TESTTAG_INSPECTION_CONFIG_CLEAR_ERROR = 'TESTTAG_INSPECTION_CONFIG_CLEAR_ERROR';

// Locations
export const TESTTAG_LOCATION_ADDING = 'TESTTAG_LOCATION_ADDING';
export const TESTTAG_LOCATION_ADDED = 'TESTTAG_LOCATION_ADDED';
export const TESTTAG_LOCATION_ADD_FAILED = 'TESTTAG_LOCATION_ADD_FAILED';
export const TESTTAG_LOCATION_ADD_CLEAR_ERROR = 'TESTTAG_LOCATION_ADD_CLEAR_ERROR';
export const TESTTAG_LOCATION_UPDATING = 'TESTTAG_LOCATION_UPDATING';
export const TESTTAG_LOCATION_UPDATED = 'TESTTAG_LOCATION_UPDATED';
export const TESTTAG_LOCATION_UPDATE_FAILED = 'TESTTAG_LOCATION_UPDATE_FAILED';
export const TESTTAG_LOCATION_UPDATE_CLEAR_ERROR = 'TESTTAG_LOCATION_UPDATE_CLEAR_ERROR';
export const TESTTAG_LOCATION_DELETING = 'TESTTAG_LOCATION_DELETING';
export const TESTTAG_LOCATION_DELETED = 'TESTTAG_LOCATION_DELETED';
export const TESTTAG_LOCATION_DELETE_FAILED = 'TESTTAG_LOCATION_DELETE_FAILED';
export const TESTTAG_LOCATION_DELETE_CLEAR_ERROR = 'TESTTAG_LOCATION_DELETE_CLEAR_ERROR';

// Sites
export const TESTTAG_SITE_LIST_LOADING = 'TESTTAG_SITE_LIST_LOADING';
export const TESTTAG_SITE_LIST_LOADED = 'TESTTAG_SITE_LIST_LOADED';
export const TESTTAG_SITE_LIST_FAILED = 'TESTTAG_SITE_LIST_FAILED';
export const TESTTAG_SITE_LIST_CLEAR = 'TESTTAG_SITE_LIST_CLEAR';
export const TESTTAG_SITE_LIST_CLEAR_ERROR = 'TESTTAG_SITE_LIST_CLEAR_ERROR';

// Buildings
export const TESTTAG_BUILDING_LIST_LOADING = 'TESTTAG_BUILDING_LIST_LOADING';
export const TESTTAG_BUILDING_LIST_LOADED = 'TESTTAG_BUILDING_LIST_LOADED';
export const TESTTAG_BUILDING_LIST_FAILED = 'TESTTAG_BUILDING_LIST_FAILED';
export const TESTTAG_BUILDING_LIST_CLEAR = 'TESTTAG_BUILDING_LIST_CLEAR';
export const TESTTAG_BUILDING_LIST_CLEAR_ERROR = 'TESTTAG_BUILDING_LIST_CLEAR_ERROR';
// Floors
export const TESTTAG_FLOOR_LIST_LOADING = 'TESTTAG_FLOOR_LIST_LOADING';
export const TESTTAG_FLOOR_LIST_LOADED = 'TESTTAG_FLOOR_LIST_LOADED';
export const TESTTAG_FLOOR_LIST_FAILED = 'TESTTAG_FLOOR_LIST_FAILED';
export const TESTTAG_FLOOR_LIST_CLEAR = 'TESTTAG_FLOOR_LIST_CLEAR';
export const TESTTAG_FLOOR_LIST_CLEAR_ERROR = 'TESTTAG_FLOOR_LIST_CLEAR_ERROR';
// Rooms
export const TESTTAG_ROOM_LIST_LOADING = 'TESTTAG_ROOM_LIST_LOADING';
export const TESTTAG_ROOM_LIST_LOADED = 'TESTTAG_ROOM_LIST_LOADED';
export const TESTTAG_ROOM_LIST_FAILED = 'TESTTAG_ROOM_LIST_FAILED';
export const TESTTAG_ROOM_LIST_CLEAR = 'TESTTAG_ROOM_LIST_CLEAR';
export const TESTTAG_ROOM_LIST_CLEAR_ERROR = 'TESTTAG_ROOM_LIST_CLEAR_ERROR';

// Assets
export const TESTTAG_ASSETS_LOADING = 'TESTTAG_ASSETS_LOADING';
export const TESTTAG_ASSETS_LOADED = 'TESTTAG_ASSETS_LOADED';
export const TESTTAG_ASSETS_FAILED = 'TESTTAG_ASSETS_FAILED';
export const TESTTAG_ASSETS_CLEAR = 'TESTTAG_ASSETS_CLEAR';
export const TESTTAG_ASSETS_CLEAR_ERROR = 'TESTTAG_ASSETS_CLEAR_ERROR';
export const TESTTAG_ASSETS_MINE_LOADING = 'TESTTAG_ASSETS_MINE_LOADING';
export const TESTTAG_ASSETS_MINE_LOADED = 'TESTTAG_ASSETS_MINE_LOADED';
export const TESTTAG_ASSETS_MINE_FAILED = 'TESTTAG_ASSETS_MINE_FAILED';
export const TESTTAG_ASSETS_MINE_CLEAR = 'TESTTAG_ASSETS_MINE_CLEAR';
export const TESTTAG_ASSETS_MINE_CLEAR_ERROR = 'TESTTAG_ASSETS_MINE_CLEAR_ERROR';

// Save inspection
export const TESTTAG_SAVE_INSPECTION_SAVING = 'TESTTAG_SAVE_INSPECTION_SAVING';
export const TESTTAG_SAVE_INSPECTION_SUCCESS = 'TESTTAG_SAVE_INSPECTION_SUCCESS';
export const TESTTAG_SAVE_INSPECTION_FAILED = 'TESTTAG_SAVE_INSPECTION_FAILED';
export const TESTTAG_SAVE_INSPECTION_CLEAR = 'TESTTAG_SAVE_INSPECTION_CLEAR';
export const TESTTAG_SAVE_INSPECTION_CLEAR_ERROR = 'TESTTAG_SAVE_INSPECTION_CLEAR_ERROR';

// Save asset type
export const TESTTAG_SAVE_ASSET_TYPE_SAVING = 'TESTTAG_SAVE_ASSET_TYPE_SAVING';
export const TESTTAG_SAVE_ASSET_TYPE_SUCCESS = 'TESTTAG_SAVE_ASSET_TYPE_SUCCESS';
export const TESTTAG_SAVE_ASSET_TYPE_FAILED = 'TESTTAG_SAVE_ASSET_TYPE_FAILED';
export const TESTTAG_SAVE_ASSET_TYPE_CLEAR_ERROR = 'TESTTAG_SAVE_ASSET_TYPE_CLEAR_ERROR';
export const TESTTAG_SAVE_ASSET_TYPE_CLEAR = 'TESTTAG_SAVE_ASSET_TYPE_CLEAR';

// Inspection Device management
export const TESTTAG_INSPECTION_DEVICES_LOADING = 'TESTTAG_INSPECTION_DEVICES_LOADING';
export const TESTTAG_INSPECTION_DEVICES_LOADED = 'TESTTAG_INSPECTION_DEVICES_LOADED';
export const TESTTAG_INSPECTION_DEVICES_FAILED = 'TESTTAG_INSPECTION_DEVICES_FAILED';
export const TESTTAG_INSPECTION_DEVICES_CLEAR_ERROR = 'TESTTAG_INSPECTION_DEVICES_CLEAR_ERROR';
export const TESTTAG_INSPECTION_DEVICES_CLEAR = 'TESTTAG_INSPECTION_DEVICES_CLEAR';
export const TESTTAG_INSPECTION_DEVICES_ADDING = 'TESTTAG_INSPECTION_DEVICES_ADDING';
export const TESTTAG_INSPECTION_DEVICES_ADDED = 'TESTTAG_INSPECTION_DEVICES_ADDED';
export const TESTTAG_INSPECTION_DEVICES_ADD_FAILED = 'TESTTAG_INSPECTION_DEVICES_ADD_FAILED';
export const TESTTAG_INSPECTION_DEVICES_UPDATING = 'TESTTAG_INSPECTION_DEVICES_UPDATING';
export const TESTTAG_INSPECTION_DEVICES_UPDATED = 'TESTTAG_INSPECTION_DEVICES_UPDATED';
export const TESTTAG_INSPECTION_DEVICES_UPDATE_FAILED = 'TESTTAG_INSPECTION_DEVICES_UPDATE_FAILED';
export const TESTTAG_INSPECTION_DEVICES_DELETING = 'TESTTAG_INSPECTION_DEVICES_DELETING';
export const TESTTAG_INSPECTION_DEVICES_DELETED = 'TESTTAG_INSPECTION_DEVICES_DELETED';
export const TESTTAG_INSPECTION_DEVICES_DELETE_FAILED = 'TESTTAG_INSPECTION_DEVICES_DELETE_FAILED';

// List Asset Types
export const TESTTAG_ASSET_TYPES_LIST_LOADING = 'TESTTAG_ASSET_TYPES_LIST_LOADING';
export const TESTTAG_ASSET_TYPES_LIST_LOADED = 'TESTTAG_ASSET_TYPES_LIST_LOADED';
export const TESTTAG_ASSET_TYPES_LIST_FAILED = 'TESTTAG_ASSET_TYPES_LIST_FAILED';
export const TESTTAG_ASSET_TYPES_LIST_CLEAR = 'TESTTAG_ASSET_TYPES_LIST_CLEAR';
export const TESTTAG_ASSET_TYPES_LIST_CLEAR_ERROR = 'TESTTAG_ASSET_TYPES_LIST_CLEAR_ERROR';

// Create / Add asset type
export const TESTTAG_ASSET_TYPES_ADDING = 'TESTTAG_ASSET_TYPES_ADDING';
export const TESTTAG_ASSET_TYPES_ADDED = 'TESTTAG_ASSET_TYPES_ADDED';
export const TESTTAG_ASSET_TYPES_ADD_FAILED = 'TESTTAG_ASSET_TYPES_ADD_FAILED';

// Save Asset Types
export const TESTTAG_ASSET_TYPES_SAVING = 'TESTTAG_ASSET_TYPES_SAVING';
export const TESTTAG_ASSET_TYPES_SAVED = 'TESTTAG_ASSET_TYPES_SAVED';
export const TESTTAG_ASSET_TYPES_SAVE_FAILED = 'TESTTAG_ASSET_TYPES_SAVE_FAILED';

// Delete Asset Types
export const TESTTAG_ASSET_TYPES_DELETING = 'TESTTAG_ASSET_TYPES_DELETING';
export const TESTTAG_ASSET_TYPES_DELETED = 'TESTTAG_ASSET_TYPES_DELETED';
export const TESTTAG_ASSET_TYPES_DELETE_FAILED = 'TESTTAG_ASSET_TYPES_DELETE_FAILED';

// Delete and Reassign Asset Types
export const TESTTAG_ASSET_TYPES_REASSIGNING = 'TESTTAG_ASSET_TYPES_REASSIGNING';
export const TESTTAG_ASSET_TYPES_REASSIGNED = 'TESTTAG_ASSET_TYPES_REASSIGNED';
export const TESTTAG_ASSET_TYPES_REASSIGN_FAILED = 'TESTTAG_ASSET_TYPES_REASSIGN_FAILED';

// Inspections due
export const TESTTAG_INSPECTIONS_DUE_LOADING = 'TESTTAG_INSPECTIONS_DUE_LOADING';
export const TESTTAG_INSPECTIONS_DUE_LOADED = 'TESTTAG_INSPECTIONS_DUE_LOADED';
export const TESTTAG_INSPECTIONS_DUE_FAILED = 'TESTTAG_INSPECTIONS_DUE_FAILED';
export const TESTTAG_INSPECTIONS_DUE_CLEAR = 'TESTTAG_INSPECTIONS_DUE_CLEAR';
export const TESTTAG_INSPECTIONS_DUE_CLEAR_ERROR = 'TESTTAG_INSPECTIONS_DUE_CLEAR_ERROR';

// Inspections by licenced user
export const TESTTAG_INSPECTIONS_BY_LICENCED_USER_LOADING = 'TESTTAG_INSPECTIONS_BY_LICENCED_USER_LOADINGG';
export const TESTTAG_INSPECTIONS_BY_LICENCED_USER_LOADED = 'TESTTAG_INSPECTIONS_BY_LICENCED_USER_LOADED';
export const TESTTAG_INSPECTIONS_BY_LICENCED_USER_FAILED = 'TESTTAG_INSPECTIONS_BY_LICENCED_USER_FAILED';
export const TESTTAG_LICENCED_INSPECTORS_LOADING = 'TESTTAG_LICENCED_INSPECTORS_LOADING';
export const TESTTAG_LICENCED_INSPECTORS_LOADED = 'TESTTAG_LICENCED_INSPECTORS_LOADED';
export const TESTTAG_LICENCED_INSPECTORS_FAILED = 'TESTTAG_LICENCED_INSPECTORS_FAILED';
export const TESTTAG_LICENCED_INSPECTORS_CLEAR_ERROR = 'TESTTAG_LICENCED_INSPECTORS_CLEAR_ERROR';

// Asset Report for DEPT
export const TESTTAG_TAGGED_BUILDING_LIST_LOADING = 'TESTTAG_TAGGED_BUILDING_LIST_LOADING';
export const TESTTAG_TAGGED_BUILDING_LIST_LOADED = 'TESTTAG_TAGGED_BUILDING_LIST_LOADED';
export const TESTTAG_TAGGED_BUILDING_LIST_FAILED = 'TESTTAG_TAGGED_BUILDING_LIST_FAILED';
export const TESTTAG_TAGGED_BUILDING_LIST_CLEAR_ERROR = 'TESTTAG_TAGGED_BUILDING_LIST_CLEAR_ERROR';
export const TESTTAG_ASSET_REPORT_LOADING = 'TESTTAG_ASSET_REPORT_LOADING';
export const TESTTAG_ASSET_REPORT_LOADED = 'TESTTAG_ASSET_REPORT_LOADED';
export const TESTTAG_ASSET_REPORT_FAILED = 'TESTTAG_ASSET_REPORT_FAILED';
export const TESTTAG_ASSET_REPORT_CLEAR_ERROR = 'TESTTAG_ASSET_REPORT_CLEAR_ERROR';

// Bulk asset update
export const TESTTAG_BULK_ASSET_UPDATE_SAVING = 'TESTTAG_BULK_ASSET_UPDATE_SAVING';
export const TESTTAG_BULK_ASSET_UPDATE_SUCCESS = 'TESTTAG_BULK_ASSET_UPDATE_SUCCESS';
export const TESTTAG_BULK_ASSET_UPDATE_FAILED = 'TESTTAG_BULK_ASSET_UPDATE_FAILED';
export const TESTTAG_BULK_ASSET_UPDATE_CLEAR = 'TESTTAG_BULK_ASSET_UPDATE_CLEAR';
export const TESTTAG_BULK_ASSET_UPDATE_CLEAR_ERROR = 'TESTTAG_BULK_ASSET_UPDATE_CLEAR_ERROR';

// Update inspection details
export const TESTTAG_INSPECTION_DETAILS_UPDATING = 'TESTTAG_INSPECTION_DETAILS_UPDATING';
export const TESTTAG_INSPECTION_DETAILS_UPDATED = 'TESTTAG_INSPECTION_DETAILS_UPDATED';
export const TESTTAG_INSPECTION_DETAILS_UPDATE_FAILED = 'TESTTAG_INSPECTION_DETAILS_UPDATE_FAILED';
export const TESTTAG_INSPECTION_DETAILS_CLEAR = 'TESTTAG_INSPECTION_DETAILS_CLEAR';
export const TESTTAG_INSPECTION_DETAILS_CLEAR_ERROR = 'TESTTAG_INSPECTION_DETAILS_CLEAR_ERROR';
// User list manage
export const TESTTAG_USER_LIST_LOADING = 'TESTTAG_USER_LIST_LOADING';
export const TESTTAG_USER_LIST_LOADED = 'TESTTAG_USER_LIST_LOADED';
export const TESTTAG_USER_LIST_FAILED = 'TESTTAG_USER_LIST_FAILED';
export const TESTTAG_USER_LIST_LOAD_CLEAR_ERROR = 'TESTTAG_USER_LIST_LOAD_CLEAR_ERROR';
export const TESTTAG_USER_LIST_UPDATING = 'TESTTAG_USER_LIST_UPDATING';
export const TESTTAG_USER_LIST_UPDATED = 'TESTTAG_USER_LIST_UPDATED';
export const TESTTAG_USER_LIST_UPDATE_CLEAR_ERROR = 'TESTTAG_USER_LIST_UPDATE_CLEAR_ERROR';
export const TESTTAG_USER_LIST_UPDATE_FAILED = 'TESTTAG_USER_LIST_UPDATE_FAILED';
export const TESTTAG_USER_LIST_ADDING = 'TESTTAG_USER_LIST_ADDING';
export const TESTTAG_USER_LIST_ADDED = 'TESTTAG_USER_LIST_ADDED';
export const TESTTAG_USER_LIST_ADD_FAILED = 'TESTTAG_USER_LIST_ADD_FAILED';
export const TESTTAG_USER_LIST_DELETING = 'TESTTAG_USER_LIST_DELETING';
export const TESTTAG_USER_LIST_DELETED = 'TESTTAG_USER_LIST_DELETED';
export const TESTTAG_USER_LIST_DELETE_FAILED = 'TESTTAG_USER_LIST_DELETE_FAILED';
export const TESTTAG_USER_LIST_CLEAR_ERROR = 'TESTTAG_USER_LIST_CLEAR_ERROR';

export const DLOR_LIST_LOADING = 'DLOR_LIST_LOADING';
export const DLOR_LIST_LOADED = 'DLOR_LIST_LOADED';
export const DLOR_LIST_FAILED = 'DLOR_LIST_FAILED';

export const DLOR_DETAIL_LOADING = 'DLOR_DETAIL_LOADING';
export const DLOR_DETAIL_LOADED = 'DLOR_DETAIL_LOADED';
export const DLOR_DETAIL_FAILED = 'DLOR_DETAIL_FAILED';
export const DLOR_DETAIL_CLEAR = 'DLOR_DETAIL_CLEAR';

export const DLOR_CREATING = 'DLOR_CREATING';
export const DLOR_CREATED = 'DLOR_CREATED';
export const DLOR_CREATE_FAILED = 'DLOR_CREATE_FAILED';

export const DLOR_UPDATING = 'DLOR_UPDATING';
export const DLOR_UPDATED = 'DLOR_UPDATED';
export const DLOR_UPDATE_FAILED = 'DLOR_UPDATE_FAILED';

export const DLOR_FILTER_LIST_LOADING = 'DLOR_FILTER_LIST_LOADING';
export const DLOR_FILTER_LIST_LOADED = 'DLOR_FILTER_LIST_LOADED';
export const DLOR_FILTER_LIST_FAILED = 'DLOR_FILTER_LIST_FAILED';

export const DLOR_FILTER_UPDATING = 'DLOR_FILTER_UPDATING';
export const DLOR_FILTER_UPDATED = 'DLOR_FILTER_UPDATED';
export const DLOR_FILTER_UPDATE_FAILED = 'DLOR_FILTER_UPDATE_FAILED';

export const DLOR_DELETING = 'DLOR_DELETING';
export const DLOR_DELETED = 'DLOR_DELETED';
export const DLOR_DELETE_FAILED = 'DLOR_DELETE_FAILED';

export const DLOR_FILETYPE_LOADING = 'DLOR_FILETYPE_LOADING';
export const DLOR_FILETYPE_LOADED = 'DLOR_FILETYPE_LOADED';
export const DLOR_FILETYPE_FAILED = 'DLOR_FILETYPE_FAILED';

export const DLOR_TEAMLIST_LOADING = 'DLOR_TEAMLIST_LOADING';
export const DLOR_TEAMLIST_LOADED = 'DLOR_TEAMLIST_LOADED';
export const DLOR_TEAMLIST_FAILED = 'DLOR_TEAMLIST_FAILED';

export const DLOR_TEAM_DELETING = 'DLOR_TEAM_DELETING';
export const DLOR_TEAM_DELETED = 'DLOR_TEAM_DELETED';
export const DLOR_TEAM_DELETE_FAILED = 'DLOR_TEAM_DELETE_FAILED';

export const DLOR_TEAM_LOADING = 'DLOR_TEAM_LOADING';
export const DLOR_TEAM_LOADED = 'DLOR_TEAM_LOADED';
export const DLOR_TEAM_FAILED = 'DLOR_TEAM_FAILED';

export const DLOR_SERIESLIST_LOADING = 'DLOR_SERIESLIST_LOADING';
export const DLOR_SERIESLIST_LOADED = 'DLOR_SERIESLIST_LOADED';
export const DLOR_SERIESLIST_FAILED = 'DLOR_SERIESLIST_FAILED';

export const DLOR_SERIES_DELETING = 'DLOR_SERIES_DELETING';
export const DLOR_SERIES_DELETED = 'DLOR_SERIES_DELETED';
export const DLOR_SERIES_DELETE_FAILED = 'DLOR_SERIES_DELETE_FAILED';

export const DLOR_SERIES_LOADING = 'DLOR_SERIES_LOADING';
export const DLOR_SERIES_LOADED = 'DLOR_SERIES_LOADED';
export const DLOR_SERIES_FAILED = 'DLOR_SERIES_FAILED';

// Drupal Article API actions
export const DRUPAL_ARTICLES_LOADING = 'DRUPAL_ARTICLES_LOADING';
export const DRUPAL_ARTICLES_LOADED = 'DRUPAL_ARTICLES_LOADED';
export const DRUPAL_ARTICLES_FAILED = 'DRUPAL_ARTICLES_FAILED';

// Drupal Article API actions
export const JOURNAL_SEARCH_LOADING = 'JOURNAL_SEARCH_LOADING';
export const JOURNAL_SEARCH_LOADED = 'JOURNAL_SEARCH_LOADED';
export const JOURNAL_SEARCH_FAILED = 'JOURNAL_SEARCH_FAILED';

// LOANS
export const LOANS_LOADING = 'LOANS_LOADING';
export const LOANS_FAILED = 'LOANS_FAILED';
export const LOANS_LOADED = 'LOANS_LOADED';

// Vemcount - location occupancy/busyness
export const VEMCOUNT_LOADING = 'VEMCOUNT_LOADING';
export const VEMCOUNT_FAILED = 'VEMCOUNT_FAILED';
export const VEMCOUNT_LOADED = 'VEMCOUNT_LOADED';
